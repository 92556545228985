import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "./FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import useSocketStore from "./StatesStore/Socket";
import useUserDataStore from "./StatesStore/UserData";

export const InterestContext = createContext({});

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [underAge, setUnderAge] = useState(null);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [parentalConsent, setParentalConsent] = useState(null);
  const setSocket = useSocketStore((state) => state.setSocket);
  const socket = useSocketStore((state) => state.socket);
  const setUserData = useUserDataStore((state) => state.setUserData);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid) return;

    const newSocket = io(
      `${process.env.REACT_APP_SOCKET}`,
      {
        query: {
          userId: user?.uid,
        },
        transports: ["websocket"],
        autoConnect: true,
        reconnectionAttempts: 2,
        reconnection: false
      }
    );
    // newSocket.connect(); 
    setSocket(newSocket);
    // newSocket.connect(); 
    // Emit 'online' status when user logs in
    if (newSocket && user) {
      newSocket.emit("update user status", {
        userId: user.uid,
        status: "online",
      });
    }
    return () => {
      if (user) {
        newSocket.emit("update user status", {
          userId: user.uid,
          status: "offline",
        });
      }
      newSocket.disconnect();
      setSocket(newSocket);
    };

  }, [user?.uid]);

  const googleSignIn = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, googleProvider);
  };

  const facebookSignIn = () => {
    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, facebookProvider);
  };

  const logOut = () => {
    if (socket && user) {
      socket.emit("update user status", {
        userId: user.uid,
        status: "offline",
      });
      socket.disconnect()
      socket.off();
      socket.close(); 
      console.log('socket after logout',socket);
      
      setSocket(null); 
    }
    signOut(auth);
    setUser(null);
    setUnderAge(null);
    setParentalConsent(null);
    setIncompleteProfile(false);
  };

  const fetchUserData = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUnderAge(userData.underAge);
      setParentalConsent(userData.parentalConsent);
      setUserData(userData);

      const { location, favBrands, favCategories } = userData;
      console.log("userdoc in context", userData);

      const isLocationIncomplete = !location || location === "";
      const isFavBrandsIncomplete = !favBrands || favBrands.length === 0;
      const isFavCategoriesIncomplete =
        !favCategories || favCategories.length === 0;

      setIncompleteProfile(
        isLocationIncomplete ||
          isFavBrandsIncomplete ||
          isFavCategoriesIncomplete
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        await fetchUserData(currentUser.uid);

        if (!currentUser.emailVerified) {
          console.log("VerifyEmail is calling me byeeee");
          navigate("/VerifyEmail");
        }
      } else {
        socket.emit("update user status", {
          userId: currentUser.uid,
          status: "offline",
        });
        socket.disconnect()
        socket.off();
        socket.close(); 
        console.log('socket after logout',socket);
        
        setSocket(null); 
        setUnderAge(null);
        setParentalConsent(null);
        setIncompleteProfile(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const contextValue = {
    user,
    underAge,
    incompleteProfile,
    parentalConsent,
    googleSignIn,
    facebookSignIn,
    logOut,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
