import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need

// live Lookflock credentials
// const firebaseConfig = {
//   apiKey: "AIzaSyCIMP4tW-41UihwYHtb4APvxwiYJQFQIF4",
//   authDomain: "lookflock-live.firebaseapp.com",
//   projectId: "lookflock-live",
//   storageBucket: "lookflock-live.appspot.com",
//   messagingSenderId: "850744346924",
//   appId: "1:850744346924:web:8ccb61ddfc8b4ac36d8489",
//   measurementId: "G-YEML7J0N2N"
// };

// Test Lookflock credentials
const firebaseConfig = {
  apiKey: "AIzaSyAmuCukMucqGuixzJInrI-31M_EPlcaEe8",
  authDomain: "lookflock-test-e44e1.firebaseapp.com",
  projectId: "lookflock-test-e44e1",
  storageBucket: "lookflock-test-e44e1.appspot.com",
  messagingSenderId: "1046242076270",
  appId: "1:1046242076270:web:7b62cf8d96d7bd0bb2287f",
};
// // Test 2 Lookflock credentials
// const firebaseConfig = {
//   apiKey: "AIzaSyD-wDH_z_pfUM2FmsGO7e_yo1a5cr2aXvQ",
//   authDomain: "lookflock-test-2.firebaseapp.com",
//   projectId: "lookflock-test-2",
//   storageBucket: "lookflock-test-2.appspot.com",
//   messagingSenderId: "755299490237",
//   appId: "1:755299490237:web:6bcb38407116df0282dad4",
// };

const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider("apple.com");
export const storage = getStorage(app);
