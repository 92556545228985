import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { formatTimestampChat } from "./lib.js/formatTimestampChat";
import { Link } from "react-router-dom";
import ProductCard3 from "../Products/ProductCard3";
import ProfilePicture from "../UserProfile/ProfilePicture";
import Picker from "emoji-picker-react";
import { decryptChat, encryptChat } from "../ShoppingList/Encryption";
import useSocketStore from "../StatesStore/Socket";
import { saveListMessageToDb } from "../ShoppingList/lib/saveListMessageToDb";
import useNotification from "../hooks/useNotification";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { saveMessageToDB } from "./lib.js/saveMsgToDb";
import { Card } from "react-bootstrap";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import ProductCard5 from "../Products/ProductCard5";
import UserCard from "../Cards/UserCard";

function ConvoScreen({ chat, selectedChat }) {
  // console.log("chat", chat);
  const [messages, setMessages] = useState([{}]);
  const [commentText, setCommentText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const [productData, setProductData] = useState(null);
  const [listMembers, setListMembers] = useState(null);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const commentRef = useRef();
  const socket = useSocketStore((state) => state.socket);
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  useEffect(() => {
    // console.log("messages", messages);
    scrollToBottom();
    // if (endRef.current) {
    //   endRef.current.scrollIntoView({ behavior: "smooth" });
    //   endRef.current.scrollTop = endRef.current.scrollHeight;
    //   console.log("SCROLL View ConvoScreen")
    // }
  }, [messages]);
  const scrollToBottom = () => {
    if (commentRef.current) {
      const chatContainer = commentRef.current.parentElement;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  };

  const fetchChatMessages = async () => {
    setLoading(true);
    const chatMessageRef = collection(db, "chats", selectedChat, "messages");
    const q = query(chatMessageRef, orderBy("timestamp", "asc"));
    const chatMessageSnap = await getDocs(q);
    const messages = await Promise.all(
      chatMessageSnap.docs.map(async (message) => {
        if (message?.data()?.type === "product" && message?.data()?.productId) {
          const productRef = doc(db, "products", message?.data()?.productId);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            return { ...message.data(), productData: productSnap.data() };
          }
          return message.data();
        } else if (message?.data()?.type === "product") {
          const productRef = doc(
            db,
            "chats",
            selectedChat,
            "messages",
            message?.id,
            "product",
            message?.data()?.productDocID
          );
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            return { ...message.data(), productData: productSnap.data() };
          }
          return message.data();
        } else return message.data();
      })
    );
    // console.log("messages", messages);
    setMessages(messages);
    setListMembers(chat?.friendsData.map((friend) => friend?.id));
    setLoading(false);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };
  const handleCommentInput = async (e) => {
    setCommentText(e.target.value);
    if (e.target.value.includes("/#/ProductDetails/")) {
      let text = e.target.value;
      // Extract the product code
      text = text.split("/#/ProductDetails/");
      // test case checking for: if no code is written is added after ProductDetails/
      if (text[1] !== "") {
        // console.log("FOUNDD", text);
        //If some extra text is used after link then do not fetch again and again for product
        if (text[1].split(" ").length === 1) {
          const productRef = doc(db, "products", text[1].split(" ")[0]);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            // console.log("FOUNDD", productSnap.data());
            setProductData(productSnap.data());
          } else setProductData(null);
        }
      }
    } else {
      setProductData(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // endRef.current.scrollIntoView({ behavior: "smooth" });
    // console.log("SCROLL View ConvoScreen3")
    scrollToBottom();
    const content = commentText;
    // Clear the comment input field
    setCommentText("");
    // console.log("CLICKED");
    const encryptedMessage = encryptChat(content);
    if (chat?.type === "private") {
      try {
        const msgData = {
          content: productData ? encryptChat("") : encryptedMessage,
          receiverId:
            chat?.members[0] !== user?.uid
              ? chat?.members[0]
              : chat?.members[1],
          senderId: user?.uid,
          type: productData ? "product" : "text",
          timestamp: serverTimestamp(),
        };

        setCommentText("");
        const commentDocRef = await saveMessageToDB(msgData);
        console.log(productData, commentDocRef);
        try {
          if (productData) {
            // console.log("ID", product?.id);
            await updateDoc(
              doc(db, "chats", chat?.id, "messages", commentDocRef),
              {
                productId: productData?.id,
              }
            );
            if (socket) {
              socket.emit("chat msg", msgData);
              setMessages((prevMsgs) => [
                ...prevMsgs,
                { ...msgData, productData, productId: productData?.id },
              ]);
            }

            setProductData(null);
          } else {
            if (socket) {
              socket.emit("chat msg", msgData);
              setMessages((prevMsgs) => [...prevMsgs, msgData]);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error adding comment: ", error);
        // Handle any errors here, e.g., display an error message to the user
      }
    } else if (chat?.type === "list") {
      try {
        const msgData = {
          content: productData ? encryptChat("") : encryptedMessage,
          senderId: user?.uid,
          listId: chat?.id,
          type: productData ? "product" : "text",
        };
        setCommentText("");
        // Fetch the comments again to include the newly added comment
        // Create a new comment document with specified attributes
        const commentDocRef = await saveListMessageToDb(
          msgData,
          chat?.id,
          listMembers
        );
        try {
          if (productData) {
            await updateDoc(
              doc(db, "chats", chat?.id, "messages", commentDocRef),
              {
                productId: productData?.id,
              }
            );
            if (socket) {
              // Emit the message to all list members
              socket.emit("list chat msg", {
                listId: chat?.id,
                msg: msgData,
              });

              setMessages((prevMsgs) => [
                ...prevMsgs,
                { ...msgData, productData, productId: productData?.id },
              ]);
            }

            setProductData(null);
          } else {
            if (socket) {
              // Emit the message to all list members
              socket.emit("list chat msg", {
                listId: chat?.id,
                msg: msgData,
              });
              setMessages((prevMsgs) => [...prevMsgs, msgData]);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error adding comment: ", error);
        // Handle any errors here, e.g., display an error message to the user
      }
    }
  };
  const handleAddProductToList = async (productID, product) => {
    const userRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      chat?.listName,
      "products",
      productID
    );
    await setDoc(userRef, {
      size: "",
      timestamp: serverTimestamp(),
      likeCount: 0,
    });
    // Increment the shoppingListCount
    await updateDoc(doc(db, "users", user.uid), {
      shoppingListCount: increment(1),
    });
    toast.success(`Product Added to ${chat?.listName} list`, toastOptions);
  };
  useEffect(() => {
    if (chat?.type === "private") {
      socket.on("chat msg", (msgrecv) => {
        setMessages((prevMsgs) => [...prevMsgs, msgrecv]);
      });
      socket.on("user status", ({ userId, status }) => {
        // console.log(userId, status);
        if (
          userId ===
          (user?.uid === chat?.friendsData[0]?.id
            ? chat?.friendsData[1]
            : chat?.friendsData[0]
          )?.id
        ) {
          setIsRecipientOnline(status === "online");
        }
      });
      // Request initial status of the recipient
      socket.emit(
        "get user status",
        (user?.uid === chat?.friendsData[0]?.id
          ? chat?.friendsData[1]
          : chat?.friendsData[0]
        )?.id
      );
    }
    // return () => socket.close();
  }, [user?.uid, chat]);

  useEffect(() => {
    if (!chat || !selectedChat) return;
    fetchChatMessages();
  }, [chat, selectedChat]);
  useEffect(() => {
    // console.log("CURRENT");
    // if (commentRef && commentRef.current) {
    //   commentRef.current.scrollTop = commentRef.current.scrollHeight;
    //   commentRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    scrollToBottom();
  }, [commentRef]);
  if (loading) {
    return (
      <div
        className="card col-lg-9 ps-0 card-chat rounded-start-lg-0 border-start-lg-0"
        style={{ height: "81vh" }}
      >
        <div className="card-body  d-flex justify-content-center align-items-center">
          {!chat ? (
            <h3 className="fw-semibold">"No chats to show"</h3>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="col-lg-9 ps-0">
      <Card className="card-chat rounded-2" style={{ height: "81vh" }}>
        <Card.Header className="d-sm-flex justify-content-between align-items-center py-2 px-3">
          <div
            className={`d-flex mb-2 mb-${
              chat?.friendsData.length > 2 ? "lg-1" : "lg-0"
            }`}
          >
            {chat?.type === "private" ? (
              <Link
                to={`/userProfile/${
                  (user?.uid === chat?.friendsData[0]?.id
                    ? chat?.friendsData[1]
                    : chat?.friendsData[0]
                  )?.id
                }`}
                className="d-flex align-items-center"
              >
                <div className="flex-shrink-0 avatar me-2">
                  <ProfilePicture
                    userData={
                      user?.uid === chat?.friendsData[0]?.id
                        ? chat?.friendsData[1]
                        : chat?.friendsData[0]
                    }
                  />
                </div>
                <div className="d-block flex-grow-1">
                  <h6 className="mb-0 mt-1">
                    {
                      (user?.uid === chat?.friendsData[0]?.id
                        ? chat?.friendsData[1]
                        : chat?.friendsData[0]
                      )?.userName
                    }
                  </h6>
                  <div className="small text-secondary">
                    <i
                      className={`fa-solid fa-circle ${
                        isRecipientOnline ? "text-success" : "text-muted"
                      } me-1`}
                    ></i>
                    {isRecipientOnline ? "Online" : "Offline"}
                  </div>
                </div>
              </Link>
            ) : (
              <Link className="d-flex" to={"/shoppingLists/"}>
                {chat?.friendsData.length === 1 ? (
                  <div className="flex-shrink-0 avatar">
                    <ProfilePicture userData={chat?.friendsData[0]} />
                  </div>
                ) : (
                  <ul
                    className={`mb-0 avatar-group avatar-group-${
                      chat?.friendsData.length === 2 ? "two" : "three"
                    }`}
                  >
                    {chat?.friendsData.slice(0, 3).map((friend) => {
                      return (
                        <li className="avatar avatar-xs">
                          <ProfilePicture userData={friend} />
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="d-block ps-2">
                  <h6 className="mb-0 mt-1">{chat?.listName}</h6>
                  <h6 className="mb-0 fw-light">
                    {chat?.friendsData.map((friend, index) => {
                      if (friend?.id === user?.uid)
                        return `${index !== 0 ? ", You" : "You"}`;
                      else
                        return (
                          `${index !== 0 ? ", " : ""}` +
                          friend.userName.split(" ")[0]
                        );
                    })}
                  </h6>
                </div>
              </Link>
            )}
          </div>
        </Card.Header>
        <Card.Body className="p-0 m-0 h-75">
          {/* <div className="fade tab-pane show active h-100"> */}
          {/* <!-- Chat conversation START --> */}
          <div className="chat-conversation-content overflow-auto px-3 h-100 pt-3 pb-2">
            {/* <!-- Chat time --> */}
            {/* <div className="text-center small my-2">
                    Jul 16, 2022, 06:15 am
                  </div> */}
            {messages.map((message) => {
              // console.log("message", message);
              return (
                <>
                  {message.type === "product" && (
                    <div
                      className={`d-flex justify-content-${
                        message?.senderId === user?.uid ? "end" : "start"
                      }`}
                    >
                      <div className="col-3">
                        <ProductCard3
                          product={message?.productData}
                          isStrip={false}
                          addButtonData={
                            chat?.type === "list" && {
                              isAddButton: true,
                              handleAddProductToList,
                              selectedShopList: chat?.listName,
                              userId: user?.uid,
                            }
                          }
                        />
                        {decryptChat(message?.content) !== "" ? (
                          <>
                            <div className="bg-primary text-white p-2 px-3 rounded-2">
                              {decryptChat(message?.content)}
                            </div>
                            <div className="small my-2">
                              {formatTimestampChat(message?.timestamp)}
                            </div>
                          </>
                        ) : (
                          <div className="small my-2 px-1">
                            {formatTimestampChat(message?.timestamp)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!message.productId && (
                    <div className="d-flex justify-content-end text-end mb-1">
                      <div className="w-100">
                        <div
                          className={`d-flex flex-row justify-content-${
                            message?.senderId === user?.uid ? "end" : "start"
                          }`}
                        >
                          {message.senderId !== user?.uid && (
                            <Link
                              to={`/userProfile/${message?.senderId}`}
                              className="flex-shrink-0 avatar avatar-xs me-2"
                            >
                              <ProfilePicture
                                userData={
                                  chat?.friendsData?.filter(
                                    (friend) => friend?.id === message?.senderId
                                  )[0] || { userId: message?.senderId }
                                }
                                // userData={{
                                //   photo: message?.photo,
                                //   uid: message?.senderId,
                                //   userName: message?.userName,
                                // }}
                                className="avatar-img rounded"
                              />
                            </Link>
                          )}
                          <div
                            className={`d-flex flex-column align-items-${
                              message?.senderId === user?.uid ? "end" : "start"
                            } justify-content-${
                              message?.senderId === user?.uid ? "start" : "end"
                            }`}
                          >
                            <div
                              className={`${
                                message.senderId === user?.uid
                                  ? "bg-primary text-white"
                                  : "bg-light text-secondary"
                              } p-2 px-3 rounded-2`}
                            >
                              {decryptChat(message?.content)}
                            </div>
                            <div className="small my-2">
                              {formatTimestampChat(message?.timestamp)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

            <div ref={commentRef} />
          </div>
          {/* <!-- Chat conversation END --> */}
          {/* </div> */}
        </Card.Body>
        <Card.Footer className="p-0">
          <form
            className="position-relative mx-3 my-2"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className=" rounded input-group d-flex border justify-content-center align-items-center">
              <div className="input-group-prepend">
                <span
                  className="input-group-text border-0 bg-transparent"
                  onClick={toggleEmojiPicker}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-emoji-smile"></i>
                </span>
              </div>

              <input
                className="form-control border-0"
                type="text"
                placeholder="Type your message"
                value={commentText}
                onChange={(e) => handleCommentInput(e)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    !e.shiftKey &&
                    commentText.length > 0
                  ) {
                    handleSubmit(e);
                  }
                }}
              />
              <button
                className="input-group-text bg-transparent border-0"
                type="submit"
                disabled={commentText.length === 0}
              >
                <i className="bi bi-send-fill"> </i>
              </button>

              <div ref={emojiPickerRef} className="position-absolute bottom-0">
                <Picker
                  onEmojiClick={addEmoji}
                  open={showEmojiPicker}
                  emojiStyle="native"
                />
              </div>
            </div>
          </form>
        </Card.Footer>
      </Card>
      <div ref={endRef}></div>
    </div>
  );
  {
    /* <!-- Conversation item END --> */
  }
}

export default ConvoScreen;
