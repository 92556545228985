import React, { useState, useEffect, useRef } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link, useSearchParams } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  deleteDoc,
  increment,
  setDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import InviteOnList from "./InviteOnList";
import Settings from "./Settings";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { getBaseURL } from "../lib/getBaseURL";
import useModalStore from "../StatesStore/ModalStore";
import ListShareMenu from "../components/ListShareMenu";
import InvitedFriendsAvatar from "./InvitedFriendsAvatar";
import useFavStore from "../StatesStore/FavStore";
import { ChatLeftTextFill, ThreeDotsVertical } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import InvitePeople from "./InvitePeople";
import PollWidget from "./PollWidget";
import CartModal from "../Modals/CartModal";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import InvitedMembersList from "./InvitedMembersList";
import EmptyList from "./EmptyList";

const MyList = ({
  shoppingListData,
  setShoppingListData,
  activeAccordion,
  setActiveAccordion,
}) => {
  // When someone deletes a product it should be removed in the fav state
  const removeFavoriteProduct = useFavStore((state) => state.removeFavorite);
  const favoriteProduct = useFavStore((state) => state.favorites);
  // The list which is currently selected
  const [selectedShopList, setSelectedShopList] = useState({});
  // Current List ID
  const [listId, setListId] = useState("");
  const baseURL = getBaseURL();
  const { openModal } = useModalStore();
  const { user } = UserAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // Get search parameters from the URL
  const [searchParams] = useSearchParams();
  // Extract 'name' and 'id' from the URL query params
  const listName = searchParams.get("name");
  const urlId = searchParams.get("id");
  // const [activeAccordion, setActiveAccordion] = useState(shoppingListData[0]?.listId);
  const [showComments, setShowComments] = useState({});
  console.log("activeAccordion", activeAccordion, shoppingListData[0]?.listId);
  const initialRenderRef = useRef(true);
  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleChat = (listId) => {
    // Check if the current list is closed
    if (activeAccordion !== listId) {
      // Open the list by setting activeAccordion to the current listId
      setActiveAccordion(listId);
      // Then toggle the chat visibility for this list
      setShowComments((prev) => ({
        ...prev,
        [listId]: true, // Open the chat
      }));
    } else {
      // If the list is already open, just toggle the chat visibility
      setShowComments((prev) => ({
        ...prev,
        [listId]: !prev[listId], // Toggle chat on/off
      }));
    }
  };

  useEffect(() => {
    console.log("SelectedShopList", selectedShopList);
  }, [selectedShopList]);
  console.log("shoppingListData from mylist", shoppingListData);
  useEffect(() => {
    if (initialRenderRef.current && shoppingListData.length > 0) {
      setActiveAccordion(shoppingListData[0]?.listId);
      initialRenderRef.current = false;
    }
    // If 'name' and 'id' are present in the URL, find the matching shopping list
    if (listName && urlId && shoppingListData?.length > 0) {
      const matchedList = shoppingListData.find(
        (shopList) => shopList.name === listName && shopList.listId === urlId
      );
      if (matchedList) {
        setSelectedShopList({
          name: matchedList.name,
          listId: matchedList.listId,
          products: matchedList.products,
        });
        setListId(matchedList.listId);
      }
    } else if (shoppingListData?.length > 0) {
      // Fallback to default selection if URL params are not present
      setSelectedShopList({
        name: shoppingListData[0]?.name,
        listId: shoppingListData[0]?.listId,
        products: shoppingListData[0]?.products,
      });
      setListId(shoppingListData[0]?.listId);
    }
  }, [listName, urlId, shoppingListData]);

  useEffect(() => {
    // setActiveAccordion(shoppingListData[0]?.listId);
    if (!selectedShopList) return;
    console.log("selectedShopList", selectedShopList);
  }, [selectedShopList]);

  const handleClick = () => {
    const DATA = {
      deleteFunction: handleDeleteList,
      displayName: selectedShopList?.name,
    };
    openModal("ConfirmDelete", "shoppingList", DATA);
  };
  const handleDeleteList = async () => {
    if (!user) return;

    try {
      const selectedShoppingListDoc = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name
      );

      // Get the listId before deleting the shopping list
      const listId = selectedShopList?.listId;

      if (!listId) throw new Error("ListId not found.");

      // Fetch all member document IDs from the "members" subcollection
      const membersCollectionRef = collection(
        selectedShoppingListDoc,
        "members"
      );
      const membersSnap = await getDocs(membersCollectionRef);
      const memberIds = membersSnap.docs.map((doc) => doc.id);

      // Delete the shopping list document
      await deleteDoc(selectedShoppingListDoc);
      console.log("Shopping list deleted successfully");

      // For each member ID, delete the corresponding document from their "invitedList" subcollection
      const deleteInvitedListPromises = memberIds.map(async (memberId) => {
        const invitedListDoc = doc(
          db,
          "users",
          memberId,
          "invitedList",
          listId
        );
        await deleteDoc(invitedListDoc);
        console.log(
          `Deleted listId ${listId} from user ${memberId}'s invitedList`
        );
      });

      Promise.all(deleteInvitedListPromises);

      console.log("All related documents deleted successfully.");

      // Fetch the product count for the shopping list
      const docCountRef = collection(selectedShoppingListDoc, "products");
      const docCountSnap = await getDocs(docCountRef);
      const countDocs = docCountSnap.size;

      // Remove favorite products
      // eslint-disable-next-line
      favoriteProduct.map((favProd) => {
        removeFavoriteProduct(favProd);
      });
      console.log(favoriteProduct);

      // Update the local state by removing the deleted list
      const remainingLists = shoppingListData.filter(
        (list) => list?.name !== selectedShopList?.name
      );

      // Sort the remaining lists by dateCreated in descending order
      remainingLists.sort((a, b) => a.dateCreated - b.dateCreated);

      // Set selectedShopList to the name of the first list in the sorted array
      setSelectedShopList({
        name: remainingLists[0]?.name,
        listId: remainingLists[0]?.listId,
      });

      // Update the local state
      setShoppingListData(remainingLists);

      // Update the user document with the decremented count
      const userRef = doc(db, "users", user?.uid);
      await updateDoc(userRef, {
        shoppingListCount: increment(-countDocs),
      });

      const chatsRef = doc(db, "chats", listId);
      await deleteDoc(chatsRef);

      console.log("State and Firestore updated successfully.");
    } catch (error) {
      console.error("Error deleting the shopping list:", error.message);
    }
  };

  const handleshopListClick = async (shopListName, shopListId) => {
    const selectedShopListData = shoppingListData?.find(
      (shopList) =>
        shopList?.name === shopListName && shopList?.listId === shopListId
    );
    if (selectedShopListData) {
      setListId(selectedShopListData?.listId);
    }
    setSelectedShopList({
      name: selectedShopListData?.name,
      listId: selectedShopListData?.listId,
      products: selectedShopListData?.products,
    });
  };
  const toggleAccordion = (listId) => {
    setActiveAccordion((prev) => (prev === listId ? null : listId)); // Toggle accordion state
  };

  return (
    <>
      {shoppingListData.length > 0 ? (
        shoppingListData.map((list) => (
          <div
            className="card mb-0 rounded-2 pt-0 pb-0 shoppingList"
            key={list.listId}
            onClick={() => toggleAccordion(list.listId)} // Toggle accordion on card click
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5
                className="card-title  pt-3 cursor-pointer ps-4"
                onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on the title
              >
                {list.name}
              </h5>

              <div className="d-none d-md-flex d-flex nav gap-2 pb-0 align-items-center justify-content-center justify-content-sm-end pt-2">
                <div
                  className="d-flex gap-2 "
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <InvitedFriendsAvatar
                  listName={list.name}
                  listId={list.listId}
                  userId={user?.uid}
                /> */}

                  <InvitePeople
                    selectedShopList={list}
                    listId={list.listId}
                    listName={list?.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                  />
                </div>

                <li
                  className="nav-item"
                  onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on li
                >
                  <Link
                    className="nav-link icon btn btn-light p-2"
                    onClick={() => toggleChat(list.listId)}
                  >
                    <ChatLeftTextFill className="fs-6" /> Chat
                  </Link>
                </li>
                <div onClick={(e) => e.stopPropagation()}>
                  <ListShareMenu
                    listName={list.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                    listId={list.listId}
                    userId={user?.uid}
                    products={list.products}
                  />
                </div>
                <style jsx>
                  {`
                    .shoppingList .dropdown-toggle:after {
                      display: none;
                    }
                  `}
                </style>
                {/* DropDown for medium screen */}

                <>
                  <Dropdown
                    className=" pb-2 pe-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle as="a" className="nav-link btn p-0 fs-3">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>
                    {/* <!-- Dropdown menu --> */}
                    <Dropdown.Menu className="dropdown-menu-end mt-0 p-2 pt-0">
                      <Dropdown.Item className="p-0">
                        {/* <InvitePeople
                      selectedShopList={selectedShopList}
                      listId={listId}
                      listName={selectedShopList?.name}
                      myName={user?.displayName}
                      listLink={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                    /> */}
                      </Dropdown.Item>
                      {/* <ListShareMenu
                    listName={list.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                    listId={list.listId}
                    userId={user?.uid}
                    products={list.products}
                  /> */}

                      <Dropdown.Item className="p-0">
                        <Settings selectedShopList={list.name} />
                      </Dropdown.Item>
                      <Dropdown.Item className="mb-2 p-0">
                        <CartModal
                          listName={list.name}
                          listProducts={list.products}
                          userId={user?.uid}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item className="p-0">
                        <div className="d-flex align-items-center">
                          <li className="nav-item" onClick={handleClick}>
                            <Link
                              className="icon-md btn btn-light p-0 text-body"
                              to=""
                            >
                              <i className="bi bi-trash-fill fs-6"> </i>
                            </Link>
                            <span className="mx-2 h6 fw-light">
                              Delete list
                            </span>
                          </li>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              </div>

              {/* DropDown for small screen */}
              {isSmallScreen && (
                <div className="d-flex">
                  <span className=" d-flex nav gap-2 pb-0 align-items-en  justify-content-center justify-content-end py-2 ">
                    <div
                      className="d-flex  "
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <InvitedFriendsAvatar
                 listName={list.name}
                 listId={list.listId}
                 userId={user?.uid}
               /> */}
                      <InvitePeople
                        selectedShopList={list}
                        listId={list.listId}
                        listName={list?.name}
                        myName={user?.displayName}
                        listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                      />
                    </div>

                    <li
                      className="nav-item"
                      onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on li
                    >
                      <Link
                        className="nav-link icon btn btn-light p-2"
                        onClick={() => toggleChat(list.listId)}
                      >
                        <ChatLeftTextFill className="fs-6" /> Chat
                      </Link>
                    </li>
                  </span>
                  <Dropdown
                    className="d-md-none py-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle as="a" className="nav-link btn p-0 fs-1">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>
                    {/* <!-- Dropdown menu --> */}
                    <Dropdown.Menu className="dropdown-menu-end mt-0 p-2">
                      <Dropdown.Item className="p-0">
                        {/* <InvitePeople
                      selectedShopList={selectedShopList}
                      listId={listId}
                      listName={selectedShopList?.name}
                      myName={user?.displayName}
                      listLink={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                    /> */}
                      </Dropdown.Item>
                      <ListShareMenu
                        listName={list.name}
                        myName={user?.displayName}
                        listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                        listId={list.listId}
                        userId={user?.uid}
                        products={list.products}
                      />
                      <Dropdown.Item className="p-0">
                        <Settings selectedShopList={list.name} />
                      </Dropdown.Item>
                      <Dropdown.Item className="mb-2 p-0">
                        <CartModal
                          listName={list.name}
                          listProducts={list.products}
                          userId={user?.uid}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item className="p-0">
                        <div className="d-flex align-items-center">
                          <li className="nav-item" onClick={handleClick}>
                            <Link
                              className="icon-md btn btn-light p-0 text-body"
                              to=""
                            >
                              <i className="bi bi-trash-fill fs-6"> </i>
                            </Link>
                            <span className="mx-2 h6 fw-light">
                              Delete list
                            </span>
                          </li>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>

            {!isSmallScreen && (
              <InvitedMembersList
                listName={list.name}
                userId={user?.uid}
                listId={list.listId}
              />
            )}

            <div
              style={{ maxWidth: isSmallScreen ? "100%" : "61vw" }}
              onClick={(e) => e.stopPropagation()}
            >
              {activeAccordion === list.listId && (
                <>
                  {shoppingListData?.length > 0 && <hr className="m-0 p-0" />}
                  <ProductList
                    shoppingListData={shoppingListData}
                    selectedShopListName={list.name}
                    selectedShopListId={list.listId}
                    userId={user?.uid}
                    componentName={"UserProfile"}
                  />
                  <hr className="m-0 p-0" />
                  {!!showComments[list.listId] && (
                    <ListComments
                      userId={user?.uid}
                      listId={list.listId}
                      shoppingListData={shoppingListData}
                      setShoppingListData={setShoppingListData}
                      selectedShopList={list?.name}
                      notify={false}
                      show={!!showComments[list.listId]}
                      toggleChat={toggleChat}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div
          className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList"
          style={{ height: "25rem", maxHeight: "25rem" }}
        >
          <EmptyList content={"There is no list available to display"} />
        </div>
      )}
    </>
  );
};

export default MyList;
