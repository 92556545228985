import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { Button, Collapse, Dropdown, Nav } from "react-bootstrap";
import HeaderMenu from "../Header/HeaderMenu";
import { UserAuth } from "../Context";
import useModalStore from "../StatesStore/ModalStore";
import CategoryMenu from "./CategoryMenu";
import BrandsMenu from "./BrandsMenu";
import CategoryMenu2 from "./CategoryMenu2";
import CategoryMenuMobile from "./CategoryMenuMobile";
import HeaderMenu3 from "./HeaderMenu3";

function SecondHeader() {
  const location = useLocation();
  const [option, setOption] = useState("NewsFeed");
  const [openBrand, setOpenBrand] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const { user } = UserAuth();
  const { openModal } = useModalStore();

  const handleToggleBrand = () => {
    setOpenBrand(!openBrand);
    setOpenCategory(false);
    setOption("Brands");
  };
  const handleToggleCategory = () => {
    setOpenCategory(!openCategory);
    setOpenBrand(false);
    setOption("Categories");
  };
  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    const category = document.getElementById("categoryCollapse");
    if (category.classList.contains("show")) {
      category.classList.remove("show");
    }
  };
  return (
    <>
      <nav className="navbar navbar-light navbar-expand-md d-block bg-mode shadow-sm mb-0 secondHeader">
        <style jsx>
          {`
            .secondHeader .navbar-nav .nav-link {
              line-height: 3;
            }
          `}
        </style>
        <div className="container w-100">
          <div className="col-lg-20"></div>
          <ul className="d-flex d-md-none flex-row justify-content-between align-items-center gap-4 navbar-nav w-100">
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/" && "active"}`}
                to={user ? "/" : "#"}
                style={{ fontSize: "0.9rem" }}
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault(); // Prevent routing
                    openModal("SignInModal", "", ""); // Show the SignIn modal
                    return;
                  }
                  setOption("NewsFeed");
                  setOpenCategory(false);
                  setOpenBrand(false);
                  handleOutsideClick();
                }}
              >
                News Feed
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/discover" &&
                  "active"}`}
                to={"/discover"}
                style={{ fontSize: "0.9rem" }}
                aria-current="page"
                onClick={() => {
                  setOption("Discover");
                  setOpenBrand(false);
                  setOpenCategory(false);
                  handleOutsideClick();
                }}
              >
                Discover
              </Link>
            </li>
            <li className="nav-item">
              <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                data-bs-toggle="collapse"
                data-bs-target="#brandsCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link btn ${option === "Brands" &&
                  "active"} text-decoration-none`}
                aria-controls="brandsCollapse"
                aria-expanded="false"
                onClick={() => {
                  setOption("Brands");
                  handleOutsideClick();
                }}
              >
                Brands
              </button>
              <div
                id="brandsCollapse"
                className="pb-0 collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                <HeaderMenu type={"brand"} />
              </div>
            </li>
            {/* Original */}
            <li className="nav-item">
              <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                data-bs-toggle="collapse"
                data-bs-target="#categoryCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link ${option === "Categories" &&
                  "active"} btn text-decoration-none"
                aria-controls="categoryCollapse`}
                aria-expanded="false"
                onClick={() => {
                  setOption("Categories");
                  handleOutsideClick();
                }}
              >
                Products
              </button>
              <div
                id="categoryCollapse"
                className="collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                <HeaderMenu3 />
                {/* <HeaderMenu type={"category"} /> */}
              </div>
            </li>
            <style jsx>
              {`
                .navDropDown .dropdown-toggle {
                  background: none;
                  border: none;
                }
                .navDropDown .dropdown-toggle:after {
                  display: none;
                }
              `}
            </style>
            <Dropdown className="nav-item navDropDown">
              <Dropdown.Toggle className="nav-link">
                <ThreeDotsVertical onClick={() => handleOutsideClick()} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="h6 bg-white">
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/termsAndConditions"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/termsAndConditions"}
                >
                  Terms of Service
                </Link>
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/contactUs"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/contactUs"}
                >
                  Contact us
                </Link>
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/privacyPolicy"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/privacyPolicy"}
                >
                  Privacy Policy
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <ul className="d-none d-md-flex flex-row justify-content-between align-items-center navbar-nav w-100 ms-1">
            {/* <div className="d-flex gap-3"> */}
            <li className="nav-item">
              <Link
                className={`nav-link ps-0 ${location.pathname === "/" &&
                  "active"}`}
                to={user ? "/" : "#"}
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault(); // Prevent routing
                    openModal("SignInModal", "", ""); // Show the SignIn modal
                    return;
                  }
                  setOption("NewsFeed");
                  setOpenCategory(false);
                  setOpenBrand(false);
                  handleOutsideClick();
                }}
              >
                News Feed
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === "/discover" &&
                  "active"}`}
                to={"/discover"}
                aria-current="page"
                onClick={() => {
                  setOption("Discover");
                  setOpenBrand(false);
                  setOpenCategory(false);
                  handleOutsideClick();
                }}
              >
                Discover
              </Link>
            </li>
            <li className="nav-item dropdown ">
              <div
                className="nav-link dropdown-toggle"
                style={{ cursor: "pointer" }}
                id="brandMenu"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Brands
              </div>
              <BrandsMenu />
            </li>
            <li className="nav-item dropdown ">
              <div
                className="nav-link dropdown-toggle"
                style={{ cursor: "pointer" }}
                id="categoryMenu"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Products
              </div>
              <CategoryMenu2 />
            </li>
            <style jsx>
              {`
                .navDropDown .dropdown-toggle {
                  background: none;
                  border: none;
                }
                .navDropDown .dropdown-toggle:after {
                  display: none;
                }
              `}
            </style>
            <Dropdown align="end" className="nav-item navDropDown">
              <Dropdown.Toggle className="nav-link px-1">
                <ThreeDotsVertical onClick={() => handleOutsideClick()} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="h6 bg-white">
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/termsAndConditions"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/termsAndConditions"}
                >
                  Terms of Service
                </Link>
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/contactUs"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/contactUs"}
                >
                  Contact us
                </Link>
                <Link
                  className={`dropdown-item text-body ${
                    location.pathname === "/staticPages/privacyPolicy"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/privacyPolicy"}
                >
                  Privacy Policy
                </Link>
              </Dropdown.Menu>
            </Dropdown>
            {/* </div> */}
            {/* <div className="d-flex">
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link fw-light pt-1 ${location.pathname ===
                    "/contactUs" && "active"}`}
                  to={"/staticPages/contactUs"}
                  aria-current="page"
                  onClick={() => {
                    setOption("ContactUs");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link fw-light pt-1 ${location.pathname ===
                    "/staticPages/termsAndConditions" && "active"}`}
                  to={"/staticPages/termsAndConditions"}
                  aria-current="page"
                  onClick={() => {
                    setOption("TOS");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`ps-2 nav-link fw-light pt-1 ${location.pathname ===
                    "/staticPages/privacyPolicy" && "active"}`}
                  to={"/staticPages/privacyPolicy"}
                  aria-current="page"
                  onClick={() => {
                    setOption("PP");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
            </div> */}
          </ul>
          <div className="col-lg-20"></div>
        </div>
      </nav>
    </>
  );
}

export default SecondHeader;
