import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Card,
  Form,
  InputGroup,
  Nav,
  Badge,
} from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { decryptChat } from "../ShoppingList/Encryption";
import { UserAuth } from "../Context";
import LoadingSpinner from "../UserProfile/LoadingSpinner";

function SideBar({ chats, selectedChat, setSelectedChat }) {
  const { user } = UserAuth();
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!chats) return;
    console.log("chats from sidebar", chats);
  }, [chats]);

  return (
    <div className="col-lg-3 pe-0">
      <nav className="navbar navbar-expand-lg m-0 p-0 me-lg-2">
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasSideChatbar"
          role="tablist"
        >
          <div class="offcanvas-header">
            <button
              type="button"
              class="btn-close text-reset ms-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          {/* Advanced filter responsive toggler END */}
          <Card className="offcanvas-body p-0 pb-2" style={{ height: "81vh" }}>
            <Card.Header className="py-3 px-3 mt-1">
              <h1 className="h5 mb-0 d-flex justify-content-between align-items-center ">
                <div>Active Chats</div>
                <Badge bg="success" className="bg-opacity-10 text-success">
                  {chats.length || 0}
                </Badge>
              </h1>
            </Card.Header>
            <Card.Body className="rounded-2 p-0 pt-3 px-3 h-75">
              <div
                className="chat-list-content h-100"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Nav className="d-flex flex-column nav-pills nav-pills-soft gap-4">
                  {chats.map((chat) => {
                    console.log("chat", chat);
                    if (
                      chat?.friendsData?.some((friend) =>
                        friend.userName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) ||
                      chat?.listName
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    )
                      if (chat.type === "private")
                        return (
                          <Nav.Link
                            active={selectedChat === chat?.id}
                            className="text-start p-0 m-0"
                            key={chat?.id}
                            onClick={() => setSelectedChat(chat?.id)}
                            style={{
                              maxWidth: "105%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 avatar me-2">
                                <ProfilePicture
                                  userData={
                                    user?.uid === chat.friendsData[0].id
                                      ? chat.friendsData[1]
                                      : chat.friendsData[0]
                                  }
                                />
                              </div>
                              <div className="flex-grow-1 d-block">
                                <h6 className="mb-0 mt-1">
                                  {(user?.uid === chat.friendsData[0].id
                                    ? chat.friendsData[1]
                                    : chat.friendsData[0]
                                  ).userName
                                    .split(" ")
                                    .slice(0, 2)
                                    .join(" ")}
                                </h6>
                                <div
                                  className="small text-secondary"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {decryptChat(chat?.lastMessage?.content)}
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        );
                      else if (chat.type === "list")
                        return (
                          <Nav.Link
                            active={selectedChat === chat?.id}
                            className="text-start p-0 m-0"
                            key={chat?.id}
                            onClick={() => setSelectedChat(chat?.id)}
                            style={{
                              maxWidth: "105%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 avatar me-2">
                                {chat.members.length === 1 ? (
                                  <div className="flex-shrink-0 avatar me-2">
                                    <ProfilePicture
                                      userData={chat.friendsData[0]}
                                    />
                                  </div>
                                ) : (
                                  <ul
                                    className={`avatar-group avatar-group-${
                                      chat.friendsData.length === 2
                                        ? "two"
                                        : "three"
                                    }`}
                                  >
                                    {chat.friendsData
                                      .slice(0, 3)
                                      .map((friend) => {
                                        return (
                                          <li className="avatar avatar-xs">
                                            <ProfilePicture userData={friend} />
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                              </div>
                              <div>
                                <h6 className="mb-0 mt-1">{chat?.listName}</h6>
                                <div className="small text-secondary">
                                  {decryptChat(chat?.lastMessage?.content)}
                                </div>
                              </div>
                            </div>
                          </Nav.Link>
                        );
                  })}
                </Nav>
              </div>
            </Card.Body>
          </Card>

          {/* <Card className="card-chat-list rounded-2 card-body border-end-lg-0 rounded-top-0 p-0">
        <Form className="position-relative">
            <InputGroup>
              <Form.Control
                type="search"
                placeholder="Search for chats"
                aria-label="Search"
                className="py-2"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button variant="transparent" type="submit">
                <i className="bi bi-search fs-5"></i>
              </Button>
            </InputGroup>
          </Form>

        <div
              className="mt-4 h-100"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Nav className="flex-column nav-pills nav-pills-soft">
                {chats.map((chat) => {
                  console.log("chat", chat);
                  if (
                    chat?.friendsData?.some((friend) =>
                      friend.userName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) ||
                    chat?.listName?.toLowerCase().includes(search.toLowerCase())
                  )
                    if (chat.type === "private")
                      return (
                        <Nav.Link
                          active={selectedChat === chat?.id}
                          className="text-start ps-2"
                          key={chat?.id}
                          onClick={() => setSelectedChat(chat?.id)}
                          style={{
                            maxWidth: "105%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar me-2">
                              <ProfilePicture
                                userData={
                                  user?.uid === chat.friendsData[0].id
                                    ? chat.friendsData[1]
                                    : chat.friendsData[0]
                                }
                              />
                            </div>
                            <div className="flex-grow-1 d-block">
                              <h6 className="mb-0 mt-1">
                                {(user?.uid === chat.friendsData[0].id
                                  ? chat.friendsData[1]
                                  : chat.friendsData[0]
                                ).userName
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                              </h6>
                              <div
                                className="small text-secondary"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {decryptChat(chat?.lastMessage?.content)}
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      );
                    else if (chat.type === "list")
                      return (
                        <Nav.Link
                          active={selectedChat === chat?.id}
                          className="text-start ps-2"
                          key={chat?.id}
                          onClick={() => setSelectedChat(chat?.id)}
                          style={{
                            maxWidth: "105%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar me-2">
                              {chat.members.length === 1 ? (
                                <div className="flex-shrink-0 avatar me-2">
                                  <ProfilePicture
                                    userData={chat.friendsData[0]}
                                  />
                                </div>
                              ) : (
                                <ul
                                  className={`avatar-group avatar-group-${
                                    chat.friendsData.length === 2
                                      ? "two"
                                      : "three"
                                  }`}
                                >
                                  {chat.friendsData.map((friend) => {
                                    return (
                                      <li className="avatar avatar-xs">
                                        <ProfilePicture userData={friend} />
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                            <div>
                              <h6 className="mb-0 mt-1">{chat?.listName}</h6>
                              <div className="small text-secondary">
                                {decryptChat(chat?.lastMessage?.content)}
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      );
                })}
              </Nav>
            </div>
        </Card> */}
        </div>
      </nav>
    </div>
  );
}

export default SideBar;
