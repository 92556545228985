import React from "react";

function Step4() {
  const step4 = process.env.PUBLIC_URL + "/assets/LandingPage/step4.png";

  return (
    <div>
      <img src={step4} alt="Step4 GIF" />
    </div>
  );
}

export default Step4;
