import React from "react";

function Step3() {
  const step3 = process.env.PUBLIC_URL + "/assets/LandingPage/step3.png";

  return (
    <div>
      <img src={step3} alt="Step3 GIF" />
    </div>
  );
}

export default Step3;
