import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import UserActiveFollowing from "./ActiveFollowing";
import { useParams } from "react-router-dom";
import axios from "axios"; // Assuming you are using axios for HTTP requests
import PrivateProfile from "./PrivateProfile";
import LoadingSpinner from "./LoadingSpinner";

export default function UserFollowing({ userData, path, componentName }) {
  const { user } = UserAuth();
  const { uid } = useParams();
  const [followinglist, setFollowinglist] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(location.pathname);
  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }

    const fetchFollowingList = async () => {
      setLoading(true);
      try {
        // const response = await axios.get(process.env.REACT_APP_API+`/api/userFollowing/${uid}`);
        const response = await axios.get(
          process.env.REACT_APP_API + `/api/userFollowing/${uid}`
        );
        // console.log("response.data", response.data);
        // const data = response.data.filter((data) =>
        //   path === "users" ? data.type === "user" : data.type === "brand"
        // );
        // console.log("data followers", response.data);

        setFollowinglist(response.data);
      } catch (error) {
        console.error("Error fetching following list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowingList();
  }, [uid, path]);

  // Render a loading message or similar if the user data is not yet available
  if (loading) {
    return <LoadingSpinner />;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      <div
        className={`card ${
          componentName === "Network" ? "border-0 h-100" : "pt-3 mt-2"
        }`}
        style={{ height: "23rem", overflowY: "auto" }}
      >
        {/* Card header START */}
        <div className="card-header border-0 pb-0 pt-0 mb-2">
          <h5 className="card-title">
            {followinglist.filter(({ type }) => type === "user").length || 0}{" "}
            Following
          </h5>
        </div>

        {/* Card header END */}
        {/* Card body START */}
        <div className="card-body pt-0">
          {/* followers Item */}
          <UserActiveFollowing
            followinglist={followinglist}
            loggedInUser={user?.uid}
          />
          <div className="d-grid">{/* Load more button START */}</div>
        </div>
        {/* Card body END */}
      </div>
    </>
  );
}
