import React, { useEffect } from "react";
// import BrandsCard from "./Brands/BrandsCard";
import RightSideBar from "./SideNav/RightSideBar";
import NewsFeed from "./NewsFeed/NewsFeed";
import NewsFeed2 from "./NewsFeed/NewsFeed2";
import ClassicView from "./Experiments/Classic/Classic";
import { UserAuth } from "./Context"; // import UserAuth from your Context.js
import UnderAgeWarning from "./Notifications/UnderAgeWarning"; // import your badge component
import CollumnPage from "./Experiments/CollumnPage";
import ColumnNewsFeed from "./Experiments/ColumnNewsFeed";
import Adsense from "./Adsense";
import ShareFeed from "./NewsFeed/ShareFeed";
import NewsFeed3 from "./NewsFeed/NewsFeed3";
import useModalStore from "./StatesStore/ModalStore";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useModeState from "./StatesStore/Mode";
import useOobCodeState from "./StatesStore/OobCode";
import useApiKeyState from "./StatesStore/ApiKey";
import GoToTop from "./components/gotoTop";
import IncomepleteProfile from "./Notifications/IncompleteProfile";
import NewsFeed4 from "./NewsFeed/NewsFeed4";
import NewsFeed5 from "./NewsFeed/NewsFeed5";
import UpdateLastLogin from "./lib/getLastLogin";
import TestChat from "./Chat/TestChat";
import NewsFeed7 from "./NewsFeed/NewsFeed7";
import NewsFeed8 from "./NewsFeed/NewsFeed8";
import NewsFeed9 from "./NewsFeed/NewsFeed9";
import NewsFeed10 from "./NewsFeed/NewsFeed10";
import SideNavUserProfile from "./SideNav/SideNavUserProfile";
import io from "socket.io-client";

function Home() {
  const { user, underAge, parentalConsent, incompleteProfile } = UserAuth(); // use the UserAuth hook to access the user data
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setmode = useModeState((state) => state.setmode);
  setmode(searchParams.get("mode") || null);
  const setoobCode = useOobCodeState((state) => state.setoobCode);
  setoobCode(searchParams.get("oobCode") || null);
  const setapikey = useApiKeyState((state) => state.setapikey);
  setapikey(searchParams.get("apiKey") || null);
  const mode = useModeState((state) => state.mode);
  const { closeModal } = useModalStore();
  useEffect(() => {
    if (!user) {
      navigate("/discover"); // Redirect to discover page if user is not logged in
      return;
    }
    console.log("USERRRAAAUUUUTTTHHH", user);
    console.log("USERRRAAAUUUUTTTHHH EMAIL VERIFIED", user.emailVerified);
    if (!user.emailVerified) {
      console.log("Going to verify email, byeee");
      navigate("/VerifyEmail");
    }
    // else {
    //   navigate("/");
    // }
  }, [user]);

  useEffect(() => {
    console.log("mode", mode);
    closeModal();
    if (mode === "resetPassword") navigate("/ForgotPassword");
    else if (mode === "verifyEmail") navigate("/VerifyEmail");
  }, []);

  return (
    <>
      {/* <SideNavUserProfile /> */}
      {/* <BrandsCard /> */}
      {/* {user && underAge && !parentalConsent ? (
        <UnderAgeWarning user={user} />
      ) : null}
      {user && incompleteProfile ? <IncomepleteProfile user={user} /> : null} */}

      {user && <UpdateLastLogin userID={user.uid} />}
      {/* <Adsense /> */}
      {/* {user &&<ShareFeed/>} */}
      {/* <NewsFeed /> */}
      {/* <NewsFeed3/> */}
      {/* <GoToTop /> */}
      {/* <Link class="icon-md btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5" style={{zIndex:'10'}}  to="/chat" role="button" >
		<i class="bi bi-chat-left-text-fill"></i>
	</Link> */}
      {/* <TestChat/> */}
      {/* <NewsFeed2 /> */}
      {/* <NewsFeed4 columns={5} /> */}
      <NewsFeed10 columns={4} />
      {/* <NewsFeed7/> */}
      {/* <NewsFeed9/> */}
      {/* <NewsFeed8/> */}
      {/* <NewsFeed5 /> */}
      {/* <ColumnNewsFeed /> */}
      {/* <CollumnPage /> */}
      {/* <ClassicView /> */}
      {/* <RightSideBar /> */}
    </>
  );
}

export default Home;
