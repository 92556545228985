import React from "react";
import useSearchInputStore from "../StatesStore/SearchInputStore";

function SearchHeader() {
  const {
    searchInput,
    setSearchInput,
    resetSearchInput,
  } = useSearchInputStore();
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchInput.length > 2) {
      window.location.href = `#/search/${searchInput}`;
    }
  };
  return (
    <div className="nav-item navbar navbar-light flex-nowrap align-items-center py-2 bg-mode border border-bottom-0 border-start-0 border-end-0">
      <div className="container w-100">
        <div className="col-lg-20"></div>
        <form
          className="rounded position-relative w-100 mx-1"
          onSubmit={handleSearch}
        >
          <input
            className="form-control ps-5 bg-light"
            type="search"
            name="input"
            placeholder="Search..."
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
            type="submit"
          >
            <i className="bi bi-search fs-5"></i>
          </button>
        </form>
        <div className="col-lg-20"></div>
      </div>
    </div>
  );
}

export default SearchHeader;
