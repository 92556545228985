import React from "react";

function Step2() {
  const step1 = process.env.PUBLIC_URL + "/assets/LandingPage/step1.png";

  return (
    <div>
      <img src={step1} alt="Step2 GIF" />
    </div>
  );
}

export default Step2;
