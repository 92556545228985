import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "./SideBar";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ConvoScreen from "./ConvoScreen";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { List } from "react-bootstrap-icons";
import useSocketStore from "../StatesStore/Socket";

function Chat() {
  const { user } = UserAuth();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState("");
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [loading, setLoading] = useState(true);
  const socket = useSocketStore((state) => state.socket);
console.log('socket from chat page',socket);

  const fetchChats = async () => {
    if (!user) return;
    try {
      setLoading(true);
      const collectionRef = collection(db, "chats");
      const chatsRef = query(
        collectionRef,
        where("members", "array-contains", user?.uid),
        orderBy("updatedAt", "desc")
      );

      const chatsSnap = await getDocs(chatsRef);
      const chatsData = await Promise.all(
        chatsSnap.docs.map(async (chat) => {
          const data = chat.data();
          // const friendUIDs = data.members.filter(
          //   (member) => member !== user?.uid
          // );
          const friendUIDs = data.members;

          const friendsData = await Promise.all(
            friendUIDs.map(async (friend) => {
              const userRef = doc(db, "users", friend);
              const userSnap = await getDoc(userRef);
              const friendData = userSnap.data();
              return {
                id: friend,
                userName: friendData?.userName,
                photo: friendData?.photo,
              };
            })
          );

          return {
            id: chat.id,
            ...data,
            friendsData,
          };
        })
      );

      setChats(chatsData);
      console.log("chatsData", chatsData);

      // If no specific chat is selected, navigate to the most recent chat
      if (!chatId && chatsData.length > 0) {
        navigate(`/chat/${chatsData[0].id}`);
        setSelectedChat(chatsData[0].id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateLastSeenMsg = async () => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    try {
      await updateDoc(userRef, {
        lastSeenMsgs: serverTimestamp(),
      });
      console.log("lastSeenMsg updated successfully");
    } catch (error) {
      console.error("Error updating lastSeenMsg:", error);
    }
  };

  useEffect(() => {
   
    fetchChats();
    updateLastSeenMsg();
    document.body.scrollIntoView({ behavior: "smooth" });
    document.body.scrollTo(0, 0);

  }, [user]);

  useEffect(() => {
    if (chatId) {
      setSelectedChat(chatId);
    }
  }, [chatId]);

  const handleChatClick = (chatId) => {
    setSelectedChat(chatId);
    navigate(`/chat/${chatId}`);
  };
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="w-100 mb-4">
      <button
        className="border-0 rounded-2 me-2 icon-md btn btn-light p-0 position-absolute z-1 d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasSideChatbar"
        aria-controls="offcanvasSideChatbar"
      >
        <span className="btn btn-primary p-2">
          <List className="fs-4" />
        </span>
      </button>
      <div className="row">
        <SideBar
          chats={chats}
          selectedChat={selectedChat}
          setSelectedChat={handleChatClick}
        />
        <ConvoScreen
          chat={
            selectedChat && chats
              ? chats.find((chat) => chat.id === selectedChat)
              : null
          }
          selectedChat={selectedChat}
        />
      </div>
    </div>
  );
}

export default Chat;
