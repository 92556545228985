import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useUrlState from "../StatesStore/CurrentURL";
import useCartStore from "../StatesStore/CartStore";

function SignInMenuHeader() {
  const seturl = useUrlState((state) => state.seturl);
  const location = useLocation();
  const cartStoreCount = useCartStore((state) => state.cartItems.length);
  const [cartCount, setCartCount] = useState(cartStoreCount);

  const getLocalCartCount = () => {
    const cart = localStorage.getItem("cart");
    return cart ? Object.keys(JSON.parse(cart)).length : 0;
  };

  useEffect(() => {
    const localCartCount = getLocalCartCount();
    setCartCount(Math.max(0, localCartCount));
  }, [cartStoreCount]);

  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    const category = document.getElementById("categoryCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    if (category.classList.contains("show")) {
      category.classList.remove("show");
    }
  };
  return (
    <>
      <li className="nav-item ms-2">
        <Link
          className="nav-link icon-md btn btn-light p-0"
          to="/cart"
          onClick={() => handleOutsideClick()}
        >
          {cartCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                cartCount > 9 ? "px-1" : ""
              } bg-danger`}
              style={{
                top: "14%",
                left: "22%",
                fontSize: "10px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            >
              {cartCount}
            </span>
          )}
          <i className="bi bi-cart-fill fs-6"></i>
        </Link>
      </li>
      <li className="nav-item ms-2">
        <Link
          className="btn btn-primary-soft rounded-2"
          to="/SignIn"
          onClick={() => seturl(location?.pathname)}
        >
          <span>Log in</span>
        </Link>
      </li>
      <li className="nav-item ms-2">
        <Link
          className="btn btn-primary-soft rounded-2"
          to="/SignUp"
          onClick={() => seturl(location?.pathname)}
        >
          <span>Sign up</span>
        </Link>
      </li>
    </>
  );
}

export default SignInMenuHeader;
