import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import UserActiveFollowers from "./ActiveFollowers";
import { useParams } from "react-router-dom";
import PrivateProfile from "./PrivateProfile";
import LoadingSpinner from "./LoadingSpinner";
// import axios from "axios"; // Assuming you are using axios for HTTP requests

export default function UserFollower({ userData, componentName }) {
  const { user } = UserAuth();
  const { uid } = useParams();
  const [followerlist, setfollowerlist] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchFollowerList = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}/api/userFollowers/${uid}`
        );

        if (!response.ok) {
          throw new Error("Error fetching following list");
        }

        const data = await response.json();
        console.log("data", data);
        setfollowerlist(data);
      } catch (error) {
        console.error("Error fetching following list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowerList();
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  if (loading) {
    return <LoadingSpinner />;
  }
  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      <div
        className={`card ${
          componentName === "Network" ? "border-0 h-100" : "pt-3 mt-2"
        }`}
        style={{ height: "23rem", overflowY: "auto" }}
      >
        {/* Card header START */}
        <div class="card-header border-0 pb-0 pt-0 mb-2">
          <h5 class="card-title">{followerlist.length || 0} Followers</h5>
        </div>
        {/* Card header END */}
        {/* Card body START */}
        <div class="card-body pt-0">
          {/* followers Item */}
          <UserActiveFollowers
            followerlist={followerlist}
            loggedInUser={user?.uid}
          />
          <div class="d-grid">
            {/* Load more button START */}
            {/*             
            <a
              href="#!"
              role="button"
              class="btn btn-sm btn-loader btn-primary-soft"
              data-bs-toggle="button"
              aria-pressed="true"
            >
              <span class="load-text"> Load more followers </span>
              <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </a> */}
            {/* Load more button END */}
          </div>
        </div>
        {/* Card body END */}
      </div>
    </>
  );
}
