import React, { useState, useEffect, useRef } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link, useLocation } from "react-router-dom";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import useNotification from "../hooks/useNotification";
import { ChatLeftTextFill, ThreeDotsVertical } from "react-bootstrap-icons";
import ProfilePicture from "../UserProfile/ProfilePicture";
import EmptyList from "./EmptyList";

const InviteLists = ({
  invitedshoppingListData,
  invitedList,
  setInvitedShoppingListData,
  activeAccordion,
  setActiveAccordion,
}) => {
  const [selectedShopList, setSelectedShopList] = useState({});
  const [selectedUser, setSelectedUser] = useState([]);
  const [listId, setListId] = useState("");
  const { user } = UserAuth();
  const { addNotification } = useNotification();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // const [activeAccordion, setActiveAccordion] = useState(invitedshoppingListData[0]?.listId);
  const [showComments, setShowComments] = useState({});
  const location = useLocation();
  const initialRenderRef = useRef(true);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleChat = (listId) => {
    if (activeAccordion !== listId) {
      setActiveAccordion(listId);
      setShowComments((prev) => ({
        ...prev,
        [listId]: true,
      }));
    } else {
      setShowComments((prev) => ({
        ...prev,
        [listId]: !prev[listId],
      }));
    }
  };
  useEffect(() => {
    const urlListId = location.pathname.split("/").pop();
    if (urlListId && urlListId !== "inviteLists") {
      const matchingList = invitedshoppingListData.find(
        (list) => list.listId === urlListId
      );
      if (matchingList) {
        // Bring the matching list to the top
        const reorderedList = [
          matchingList,
          ...invitedshoppingListData.filter(
            (list) => list.listId !== urlListId
          ),
        ];

        setInvitedShoppingListData(reorderedList);
      }
      setActiveAccordion(urlListId);
      setShowComments((prev) => ({
        ...prev,
        [urlListId]: true,
      }));
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (invitedshoppingListData?.length > 0) {
  //     const urlListId = location.pathname.split("/").pop();
  //     let firstList;

  //     if (urlListId && urlListId !== "inviteLists") {
  //       firstList = invitedshoppingListData.find(list => list.listId === urlListId) || invitedshoppingListData[0];
  //     } else {
  //       firstList = invitedshoppingListData[0];
  //     }

  //     setSelectedShopList({
  //       name: firstList?.name,
  //       listId: firstList?.listId,
  //     });
  //     setListId(firstList?.listId);
  //   }
  // }, [invitedshoppingListData, location.pathname]);

  useEffect(() => {
    // setActiveAccordion(invitedshoppingListData[0]?.listId);
    if (initialRenderRef.current && invitedshoppingListData.length > 0) {
      setActiveAccordion(invitedshoppingListData[0]?.listId);
      initialRenderRef.current = false;
    }
    if (!invitedshoppingListData || !invitedList || !selectedShopList || !user)
      return;

    const fetchUserDetails = async () => {
      const selectedShopListData = invitedshoppingListData?.find(
        (shopList) =>
          shopList?.name === selectedShopList?.name &&
          shopList?.listId === selectedShopList?.listId
      );
      const selectedListID = selectedShopListData?.listId;
      const selecteduser = invitedshoppingListData?.find(
        (list) => list?.listId === selectedListID
      );
      setSelectedUser(selecteduser);

      if (selecteduser?.userId) {
        const hasSeenListDocRef = doc(
          db,
          "users",
          selecteduser?.userId,
          "shoppingList",
          selectedShopList?.name,
          "viewers",
          user?.uid
        );

        const hasSeenListSnapshot = await getDoc(hasSeenListDocRef);

        if (!hasSeenListSnapshot.exists()) {
          await addNotification({
            userId: selecteduser?.userId,
            id: user.uid,
            timestamp: serverTimestamp(),
            type: "firstTimeListView",
            by: "user",
            fields: {
              listId: selectedListID,
              shopList: selectedShopList?.name,
            },
          });

          await setDoc(hasSeenListDocRef, {
            listId: selectedListID,
            timestamp: serverTimestamp(),
            shopList: selectedShopList?.name,
          });
        }
      }
    };
    fetchUserDetails();
  }, [invitedshoppingListData, selectedShopList, invitedList, user]);

  const toggleAccordion = (listId) => {
    setActiveAccordion((prev) => (prev === listId ? null : listId));
  };

  return (
    <>
      {invitedshoppingListData.length > 0 ? (
        invitedshoppingListData.map((list) => (
          <div
            className="card mb-0 rounded-2 pt-0 pb-0 shoppingList"
            key={list.listId}
            onClick={() => toggleAccordion(list.listId)}
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex align-items-center ps-4 pt-3">
                <div
                  className="avatar me-2"
                  style={{ height: "40px", width: "40px" }}
                >
                  <Link to={`/userProfile/${list?.userId}`}>
                    <ProfilePicture
                      userData={list}
                      className="avatar-img rounded-2"
                    />
                  </Link>
                </div>
                <div>
                  <div className="d-flex align-items-center gap-2">
                    <h5 className="card-title mb-0">{list.name}</h5>
                    <div
                      className="bg-primary fw-normal text-white rounded-pill"
                      style={{ fontSize: "10px", padding: "1px 8px" }}
                    >
                      {list.products?.length || 0}
                    </div>
                  </div>
                  <i className=" small mb-0">
                    by{" "}
                    <Link
                      to={`/userProfile/${list?.userId}`}
                      className="text-decoration-none card-title "
                    >
                      {list?.userName}
                    </Link>
                  </i>
                </div>
              </div>

              <div className="d-flex nav gap-2 pb-0 align-items-center pe-3 pt-2">
                <li
                  className="nav-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleChat(list.listId);
                  }}
                >
                  <Link className="nav-link icon btn btn-light p-2">
                    <ChatLeftTextFill className="fs-6" /> Chat
                  </Link>
                </li>
              </div>
            </div>

            <div
              style={{ maxWidth: isSmallScreen ? "100%" : "61vw" }}
              onClick={(e) => e.stopPropagation()}
            >
              {activeAccordion === list.listId && (
                <>
                  <hr className="m-0 p-0" />
                  <ProductList
                    shoppingListData={invitedshoppingListData}
                    selectedShopListName={list.name}
                    selectedShopListId={list.listId}
                    userId={user?.uid}
                    role={list.role}
                    ownerId={list.userId}
                    notify={true}
                  />
                  <hr className="m-0 p-0" />
                  {!!showComments[list.listId] && (
                    <ListComments
                      userId={user?.uid}
                      listId={list.listId}
                      shoppingListData={invitedshoppingListData}
                      selectedShopList={list.name}
                      role={
                        invitedList?.find((l) => l.listId === list.listId)?.role
                      }
                      invitedBy={
                        invitedList?.find((l) => l.listId === list.listId)
                          ?.userId
                      }
                      notify={true}
                      show={!!showComments[list.listId]}
                      toggleChat={toggleChat}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div
          className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList px-5"
          style={{ height: "25rem", maxHeight: "25rem" }}
        >
          <EmptyList
            content={
              "You haven't been invited to contribute to any shopping lists yet"
            }
          />
        </div>
      )}
    </>
  );
};

export default InviteLists;
