import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { Accordion } from "react-bootstrap";
function CategoryMenu2() {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index); // Toggle the clicked accordion
  };
  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);
  const renderDropdownItems = (categories, gender) => {
    return Object.keys(categories)
      .sort()
      .map((categoryName) => (
        <li className="dropdown-submenu dropend">
          <div
            className="dropdown-item dropdown-toggle py-1"
            style={{ cursor: "pointer" }}
            // to={`/AllProducts/${categoryName}`}
          >
            {categoryName}
          </div>
          <ul className="dropdown-menu dropdown-menu-end" data-bs-popper="none">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory) => (
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                    // onClick={handleOutsideClick}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };
  return (
    <ul className="dropdown-menu py-2" aria-labelledby="categoryMenu">
      <div className="pt-2 fw-bold pb-0 px-3">Men</div>
      {renderDropdownItems(menCategories, "Men")}
      <div className="pt-2 fw-bold pb-0 px-3">Women</div>
      {renderDropdownItems(womenCategories, "Women")}
      {/* <li className="dropdown-divider"></li> */}
    </ul>
  );
}

export default CategoryMenu2;
