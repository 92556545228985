import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev "
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        left: "-7%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0.3)",
        background: "rgba(0, 0, 0, 0.6)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};

const NextArrow = ({ style, onClick }) => {
  return (
    <div
      style={{
        ...style,
        background: "rgba(0, 0, 0, 0.6)",
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        right: "-8%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};
function LandingPage() {
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: true,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="vstack card px-2 px-lg-5 pt-3 pb-0 bg-mode border-0">
      <div className="card-header bg-mode border-0 p-0 topText d-flex flex-column justify-content-center align-items-center">
        <div className="text-center">
          <div
            style={{ fontFamily: "Nunito Sans" }}
            className="h1 display-6 m-0 p-0"
          >
            <i>lookflock</i>
          </div>
          <div
            className="h5 fw-light text-body"
            style={{ marginTop: "-0.5rem" }}
          >
            Social shopping
          </div>
        </div>
      </div>
      <div className="steps card-body p-0 mb-0 m-2 m-lg-4">
        <Slider {...settings}>
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
        </Slider>
      </div>
    </div>
  );
}

export default LandingPage;
