import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import useModalStore from "../StatesStore/ModalStore";
import { dataNewsFeed, titleNewsFeed } from "../Content/ModalContent";
import { useQuery } from "@tanstack/react-query";
import { XMasonry, XBlock } from "react-xmasonry";
import UserCard from "../Cards/UserCard";
import UserCard3 from "../Cards/UserCard3";
import Skeleton from "../Products/Skeleton";

export default function NewsFeed10({ columns }) {
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const [columnCount, setColumnCount] = useState(getColumnCount());

  const fetchNewsFeed = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/newsFeedProducts3/${user?.uid}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  const { data: newsFeedItems, isLoading, error } = useQuery({
    queryKey: ["newsFeed"],
    queryFn: fetchNewsFeed,
    enabled: !!user,
  });

  useEffect(() => {
    function handleResize() {
      setColumnCount(getColumnCount());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 900) return 5;
    if (screenWidth >= 768) return 4;
    if (screenWidth >= 440) return 3;
    return 2;
  }

  function getTargetBlockWidth() {
    return window.innerWidth < 440 ? 200 : 250;
  }

  {
    isLoading &&
      Array.from({ length: 25 }).map((_, index) => (
        <XBlock key={`skeleton-${index}`} width={1}>
          <Skeleton />
        </XBlock>
      ));
  }
  if (error) {
    return <div>Error fetching news feed: {error.message}</div>;
  }
  function getBlockWidth() {
    // Return 1 for desktop (width >= 900), else 2
    return window.innerWidth >= 900 ? 1 : 2;
  }
  return (
    <>
      {/* <div className="col-md-8 col-lg-6 vstack gap-4 p-0 mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      <div className="vstack gap-4 px-0">
        <XMasonry
          center={true}
          responsive={true}
          maxColumns={columns || 5}
          targetBlockWidth={getTargetBlockWidth()}
        >
          {newsFeedItems?.map((post, index) => (
            <XBlock width={getBlockWidth()} key={post.id}>
              {/* <UserCard post={post} /> */}
              <div className={`me-0 ${index === 0 ? "ms-1" : "ms-2"}`}>
                <UserCard3 post={post} />
              </div>
            </XBlock>
          ))}
        </XMasonry>
      </div>
    </>
  );
}
