import React from "react";

function Step2() {
  const step2 = process.env.PUBLIC_URL + "/assets/LandingPage/step2.png";

  return (
    <div>
      <img src={step2} alt="Step2 GIF" />
    </div>
  );
}

export default Step2;
