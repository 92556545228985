import React from "react";
import BrandsPageSideNav from "./BrandsPageSideNav";
import { Filter } from "react-bootstrap-icons";

function SideNavBrands({ brandName }) {
  return (
    <div className="">
      {/* <div
        className="d-flex align-items-center d-lg-none position-fixed opacity-75 m-2"
        style={{ zIndex: "3" }}
      >
        <button
          className="border-0 rounded-3  "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasFilterNav"
          aria-controls="offcanvasFilterNav"
        >
          <span className="btn btn-mode p-2">
            <Filter className="fs-5" />
          </span>
        </button>
      </div> */}
      {/* Advanced filter responsive toggler END */}

      {/* Navbar START */}
      <nav className="navbar mx-0">
        <div
          className="offcanvas offcanvas-end"
          // data-bs-scroll="true"
          // data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasFilterNav"
          style={{ width: "30%" }}
        >
          {/* Offcanvas header */}
          <div className="offcanvas-header ps-2">
            <button
              type="button"
              className="btn-close px-0 me-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <BrandsPageSideNav brandName={brandName} />

          {/* Offcanvas body */}
          {/* <div className="offcanvas-body d-block px-2 px-lg-0">
            <BrandsMenu />
          </div> */}
        </div>
      </nav>
      {/* Navbar END */}
    </div>
  );
}

export default SideNavBrands;
