import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  doc,
  deleteDoc,
  collection,
  getDocs,
  increment,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { Dropdown } from "react-bootstrap";
import {
  ChatLeftTextFill,
  PersonPlusFill,
  ThreeDotsVertical,
} from "react-bootstrap-icons";

import ProductList from "../../ShoppingList/ProductList";
import { db } from "../../FirebaseConfig";
import Settings from "../../ShoppingList/Settings";
import { UserAuth } from "../../Context";
import useModalStore from "../../StatesStore/ModalStore";
import ListShareMenu from "../../components/ListShareMenu";
import InvitedFriendsAvatar from "../../ShoppingList/InvitedFriendsAvatar";
import useFavStore from "../../StatesStore/FavStore";
import CartModal from "../../Modals/CartModal";
import { getBaseURL } from "../../lib/getBaseURL";
import InvitePeople from "../../ShoppingList/InvitePeople";
import ListComments from "../../ShoppingList/ListComments";
import EmptyList from "../../ShoppingList/EmptyList";
import LoadingSpinner from "../LoadingSpinner";

const MyList = ({ shoppingListData, setShoppingListData }) => {
  const { openModal } = useModalStore();
  const { user } = UserAuth();
  const { uid } = useParams();
  const [ownerName, setOwnerName] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(
    shoppingListData[0]?.listId ?? null
  ); // First list open by default
  const [showComments, setShowComments] = useState({});
  const removeFavoriteProduct = useFavStore((state) => state.removeFavorite);
  const favoriteProduct = useFavStore((state) => state.favorites);
  const baseURL = getBaseURL();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  // Effect to handle window resize
  useEffect(() => {
    setActiveAccordion(shoppingListData[0]?.listId);
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [shoppingListData]);
  const toggleAccordion = (listId) => {
    setActiveAccordion((prev) => (prev === listId ? null : listId)); // Toggle accordion state
  };

  const toggleChat = (listId) => {
    // Check if the current list is closed
    if (activeAccordion !== listId) {
      // Open the list by setting activeAccordion to the current listId
      setActiveAccordion(listId);
      // Then toggle the chat visibility for this list
      setShowComments((prev) => ({
        ...prev,
        [listId]: true, // Open the chat
      }));
    } else {
      // If the list is already open, just toggle the chat visibility
      setShowComments((prev) => ({
        ...prev,
        [listId]: !prev[listId], // Toggle chat on/off
      }));
    }
  };

  const handleClick = () => {
    const DATA = {
      deleteFunction: handleDeleteList,
    };
    openModal("ConfirmDelete", "shoppingList", DATA);
  };

  const handleDeleteList = async (list) => {
    try {
      const selectedShoppingListDoc = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        list.name
      );
      const docCountRef = collection(selectedShoppingListDoc, "products");
      const docCountSnap = await getDocs(docCountRef);
      const countDocs = docCountSnap.size;

      await deleteDoc(selectedShoppingListDoc);

      const remainingLists = shoppingListData.filter(
        (item) => item.name !== list.name
      );
      setShoppingListData(remainingLists);

      const userRef = doc(db, "users", user?.uid);
      await updateDoc(userRef, { shoppingListCount: increment(-countDocs) });
    } catch (error) {
      console.error("Error deleting list:", error.message);
    }
  };

  useEffect(() => {
    if (!uid) return;
    const fetchOwnerName = async () => {
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);
      setOwnerName(userSnap?.data()?.userName);
    };
    fetchOwnerName();
  }, [uid]);

  return (
    <>
      {shoppingListData.length > 0 ? (
        shoppingListData.map((list) => (
          <div
            className="card mb-3 mt-2 rounded-2 pt-0 pb-0 profileShoppingList"
            key={list.listId}
            onClick={() => toggleAccordion(list.listId)} // Toggle accordion on card click
          >
            <div className="d-flex justify-content-between align-items-center mb-2 ">
              <h5
                className="card-title  pt-3 cursor-pointer ps-4"
                onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on the title
              >
                {list.name}
              </h5>
              <div className="d-none d-md-flex d-flex nav gap-2 pb-0 align-items-center justify-content-center justify-content-sm-end pt-2">
                <div
                  className="d-flex gap-2 "
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <InvitedFriendsAvatar
                  listName={list.name}
                  listId={list.listId}
                  userId={user?.uid}
                /> */}
                  {user?.uid === uid && (
                    <InvitePeople
                      selectedShopList={list}
                      listId={list.listId}
                      listName={list?.name}
                      myName={user?.displayName}
                      listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                    />
                  )}
                </div>

                {/* <li
                className="nav-item"
                onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on li
              >
                <Link
                  className="nav-link icon btn btn-light p-2"
                  onClick={() => toggleChat(list.listId)}
                >
                  <ChatLeftTextFill className="fs-6" /> Chat
                </Link>
              </li> */}
                <div className="me-3" onClick={(e) => e.stopPropagation()}>
                  <ListShareMenu
                    listName={list.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                    listId={list.listId}
                    userId={user?.uid}
                    products={list.products}
                  />
                </div>
                <style jsx>
                  {`
                    .profileShoppingList .dropdown-toggle:after {
                      display: none;
                    }
                  `}
                </style>
                {/* DropDown for medium screen */}

                <>
                  {user?.uid === uid && (
                    <Dropdown
                      className=" pb-2 pe-1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Dropdown.Toggle as="a" className="nav-link btn p-0 fs-3">
                        <ThreeDotsVertical />
                      </Dropdown.Toggle>
                      {/* <!-- Dropdown menu --> */}
                      <Dropdown.Menu className="dropdown-menu-end mt-0 p-2 pt-0">
                        <Dropdown.Item className="p-0"></Dropdown.Item>

                        <Dropdown.Item className="p-0">
                          <Settings selectedShopList={list.name} />
                        </Dropdown.Item>
                        <Dropdown.Item className="mb-0 p-0">
                          <CartModal
                            listName={list.name}
                            listProducts={list.products}
                            userId={user?.uid}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item className="p-0">
                          <div className="d-flex align-items-center">
                            <li className="nav-item" onClick={handleClick}>
                              <Link
                                className="icon-md btn btn-light p-0 text-body"
                                to=""
                              >
                                <i className="bi bi-trash-fill fs-6"> </i>
                              </Link>
                              <span className="mx-2 h6 fw-light">
                                Delete list
                              </span>
                            </li>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              </div>

              {/* DropDown for small screen */}
              {isSmallScreen && (
                <div className="d-flex">
                  <span className=" d-flex nav gap-2 pb-0 align-items-en  justify-content-center justify-content-end py-2 ">
                    <div
                      className="d-flex  "
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <InvitedFriendsAvatar
                 listName={list.name}
                 listId={list.listId}
                 userId={user?.uid}
               /> */}
                      <InvitePeople
                        selectedShopList={list}
                        listId={list.listId}
                        listName={list?.name}
                        myName={user?.displayName}
                        listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                      />
                    </div>

                    {/* <li
                  className="nav-item"
                  onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on li
                >
                  <Link
                    className="nav-link icon btn btn-light p-2"
                    onClick={() => toggleChat(list.listId)}
                  >
                    <ChatLeftTextFill className="fs-6" /> Chat
                  </Link>
                </li> */}
                  </span>
                  <Dropdown
                    className="d-md-none py-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle as="a" className="nav-link btn p-0 fs-1">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>
                    {/* <!-- Dropdown menu --> */}
                    <Dropdown.Menu className="dropdown-menu-end mt-0 p-2">
                      <Dropdown.Item className="p-0">
                        {/* <InvitePeople
                      selectedShopList={selectedShopList}
                      listId={listId}
                      listName={selectedShopList?.name}
                      myName={user?.displayName}
                      listLink={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                    /> */}
                      </Dropdown.Item>
                      <ListShareMenu
                        listName={list.name}
                        myName={user?.displayName}
                        listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                        listId={list.listId}
                        userId={user?.uid}
                        products={list.products}
                      />
                      <Dropdown.Item className="p-0">
                        <Settings selectedShopList={list.name} />
                      </Dropdown.Item>
                      <Dropdown.Item className="mb-2 p-0">
                        <CartModal
                          listName={list.name}
                          listProducts={list.products}
                          userId={user?.uid}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item className="p-0">
                        <div className="d-flex align-items-center">
                          <li className="nav-item" onClick={handleClick}>
                            <Link
                              className="icon-md btn btn-light p-0 text-body"
                              to=""
                            >
                              <i className="bi bi-trash-fill fs-6"> </i>
                            </Link>
                            <span className="mx-2 h6 fw-light">
                              Delete list
                            </span>
                          </li>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            <div
              style={{ maxWidth: isSmallScreen ? "100%" : "60vw" }}
              onClick={(e) => e.stopPropagation()}
            >
              {activeAccordion === list.listId && (
                <>
                  {shoppingListData?.length > 0 && <hr className="m-0 p-0" />}
                  <ProductList
                    shoppingListData={shoppingListData}
                    selectedShopListName={list.name}
                    selectedShopListId={list.listId}
                    userId={user?.uid}
                    componentName={"UserProfile"}
                    slidesToShow={3}
                    ownerName={ownerName}
                  />
                  {/* <hr className="m-0 p-0" /> */}
                  {/* {!!showComments[list.listId] &&<ListComments
                  userId={user?.uid}
                  listId={list.listId}
                  shoppingListData={shoppingListData}
                  setShoppingListData={setShoppingListData}
                  selectedShopList={list?.name}
                  notify={false}
                  show={!!showComments[list.listId]}
                  toggleChat={toggleChat}
                />} */}
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div
          className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList"
          style={{ height: "25rem", maxHeight: "25rem" }}
        >
          <EmptyList content={"There is no list available to display"} />
        </div>
      )}
    </>
  );
};

export default MyList;
